<template>
  <el-dialog
    :visible.sync="setWindowDialog"
    title="选择调度室窗口"
    width="485px"
    :center="true"
    class="dialog"
  >
    <div class="contents">
      <el-form
        :model="dialogForm"
        label-position="right"
        ref="dialogForm"
        :rules="rule1"
      >
        <el-form-item label="调度室窗口" prop="window_id">
          <el-select
            v-model="dialogForm.window_id"
            placeholder="请选择调度室窗口"
            class="handle-select mr10"
          >
            <el-option
              :key="index"
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in windowList"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-row v-for="(item, i) in dialogForm.print_arr" :key="i">
          <div class="position">
            <el-form-item
              :label="`${item.product_name}——打印件数`"
              :prop="`print_arr.${i}.product_qty`"
              :rules="rulesnum"
            >
              <el-input
                v-model="item.product_qty"
                :disabled="false"
                clearable
                placeholder="请输入打印件数"
              ></el-input>
            </el-form-item>
          </div>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="adminWindow">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { postTonnageOrderLabelPrint } from "@/api/logisticsControl/online-self-procurement";
export default {
  name: "PrintLabel",
  props: ["windowList"],
  data() {
    return {
      setWindowDialog: false,
      dialogForm: { window_id: "", print_arr: [] },
      rule1: {
        window_id: [
          { required: true, message: "请选择调度室窗口", trigger: "blur" },
        ],
      },
      rulesnum: [
        {
          required: true,
          message: "请输入件数",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (/^(?:[1-9]\d*)$/.test(value) == false) {
              callback(new Error("请输入正整数"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      submitLoading: false,
      order_no: "",
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "data==");
      this.order_no = data.order_no;
      this.dialogForm.print_arr = [];
      if (data.store_logistics_tonnage_order_detail.length > 0) {
        data.store_logistics_tonnage_order_detail.map((item) => {
          let obj = {
            id: item.id,
            product_qty: item.product_qty,
            product_name: item.product_name,
          };
          this.dialogForm.print_arr.push(obj);
        });
      }

      this.setWindowDialog = true;
    },
    /**
     * 确定
     */
    adminWindow() {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          let tmpList = [];
          this?.dialogForm?.print_arr.map((pItem) => {
            let pObj = {
              id: pItem.id,
              product_qty: pItem.product_qty,
            };
            tmpList.push(pObj);
          });
          console.log(tmpList, "tmpList==");
          try {
            const res = await postTonnageOrderLabelPrint({
              order_no: this.order_no,
              window_id: this.dialogForm.window_id,
              list: tmpList,
            });
            this.$message({
              message: "打印成功",
              type: "success",
            });
          } catch (error) {
            console.log(error, "postTonnageOrderLabelPrint");
          } finally {
            this.setWindowDialog = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contents {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 23px;
}
</style>
