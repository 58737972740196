/**
 * 线上自采登记订单状态
 */
export const ONLINE_SELF_PROCUREMENT_STATUS_ENUM = {
  // 0: {
  //   value: 0,
  //   label: "待支付",
  // },
  10: {
    value: 10,
    label: "支付成功",
  },
  20: {
    value: 20,
    label: "已复核",
  },
  30: {
    value: 30,
    label: "已退款",
  },
};
