var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        visible: _vm.setWindowDialog,
        title: "选择调度室窗口",
        width: "485px",
        center: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.setWindowDialog = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c("el-button", { on: { click: _vm.adminWindow } }, [
                    _vm._v("确定"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                model: _vm.dialogForm,
                "label-position": "right",
                rules: _vm.rule1,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "调度室窗口", prop: "window_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "handle-select mr10",
                      attrs: { placeholder: "请选择调度室窗口" },
                      model: {
                        value: _vm.dialogForm.window_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.dialogForm, "window_id", $$v)
                        },
                        expression: "dialogForm.window_id",
                      },
                    },
                    _vm._l(_vm.windowList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.dialogForm.print_arr, function (item, i) {
                return _c("el-row", { key: i }, [
                  _c(
                    "div",
                    { staticClass: "position" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: `${item.product_name}——打印件数`,
                            prop: `print_arr.${i}.product_qty`,
                            rules: _vm.rulesnum,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: false,
                              clearable: "",
                              placeholder: "请输入打印件数",
                            },
                            model: {
                              value: item.product_qty,
                              callback: function ($$v) {
                                _vm.$set(item, "product_qty", $$v)
                              },
                              expression: "item.product_qty",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }