import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO2;

/**
 * 零担复核
 * @param {*} params
 */
export const postTonnageOrderList = (params = {}) => {
  return axios.post(`${BASE_URL}/tonnageOrder/list`, params);
};

/**
 * 零担复核 详情
 * @param {*} params
 */
export const postTonnageOrderDetail = (params = {}) => {
  return axios.post(`${BASE_URL}/tonnageOrder/detail`, params);
};

/**
 * 零担复核 打印标签
 * @param {*} params
 */
export const postTonnageOrderLabelPrint = (params = {}) => {
  return axios.post(`${BASE_URL}/tonnageOrder/label/print`, params);
};

/**
 * 零担复核 订单复核（完成）
 * @param {*} params
 */
export const postTonnageOrderFinish = (params = {}) => {
  return axios.post(`${BASE_URL}/tonnageOrder/finish`, params);
};

/**
 * 零担复核 退款
 * @param {*} params
 */
export const postTonnageOrderRefund = (params = {}) => {
  return axios.post(`${BASE_URL}/tonnageOrder/refund`, params);
};
