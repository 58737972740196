var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "self-review bj" },
    [
      _c(
        "div",
        { staticClass: "inputtop" },
        [
          _c("el-input", {
            ref: "inputfocusc",
            attrs: {
              clearable: "",
              placeholder: "请在此输入或扫码复核码",
              autocomplete: "off",
              autofocus: "true",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.changeInput.apply(null, arguments)
              },
            },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.changeInput },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _vm.isShowInfo
            ? _c("div", { staticClass: "inputbody" }, [
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("城市仓:")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "300px",
                        margin: "0 20px",
                        "font-size": "22px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.info?.store_logistics?.name || "") +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "inputwen" }, [_vm._v("自提点:")]),
                  _c(
                    "div",
                    { staticStyle: { width: "300px", "font-size": "22px" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.info?.store_delivery?.name || "") + " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "inputitems",
                    staticStyle: { "align-items": "flex-start" },
                  },
                  [
                    _c("div", { staticClass: "inputwen1 inputwen" }, [
                      _vm._v("自采品订单："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cntsbody" },
                      _vm._l(
                        _vm.info?.store_logistics_tonnage_order_detail,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "cntlist" },
                            [
                              _vm._v(
                                " " + _vm._s(item?.product_name || "") + " "
                              ),
                              _c("span", [_vm._v(_vm._s(item?.product_qty))]),
                              _vm._v("件 共"),
                              _c("span", [
                                _vm._v(_vm._s(item?.product_weight)),
                              ]),
                              _vm._v("斤 "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 复核：" + _vm._s(item?.product_name) + " "
                                  ),
                                  _c("span", [_vm._v(_vm._s(item?.qc_qty))]),
                                  _vm._v(" 件,共 "),
                                  _c("span", [_vm._v(_vm._s(item?.qc_weight))]),
                                  _vm._v(" 斤 "),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("收货人:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "收货人姓名",
                          disabled: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.receiver_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "receiver_name", $$v)
                          },
                          expression: "info.receiver_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "收货人电话",
                          disabled: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.receiver_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "receiver_mobile", $$v)
                          },
                          expression: "info.receiver_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("发货人:")]),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "发货人姓名",
                          disabled: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.shipper_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "shipper_name", $$v)
                          },
                          expression: "info.shipper_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "widthinput" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "发货人电话",
                          disabled: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.info.shipper_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.info, "shipper_mobile", $$v)
                          },
                          expression: "info.shipper_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("用户姓名:")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "300px",
                        margin: "0 20px",
                        "font-size": "22px",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.info?.member?.fullname || "") + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "inputwen" }, [_vm._v("电话:")]),
                  _c(
                    "div",
                    { staticStyle: { width: "300px", "font-size": "22px" } },
                    [_vm._v(" " + _vm._s(_vm.info?.member?.mobile || "") + " ")]
                  ),
                ]),
                _c("div", { staticClass: "inputitems" }, [
                  _c("div", { staticClass: "inputwen" }, [_vm._v("下单时间:")]),
                  _vm._v(" " + _vm._s(_vm.info?.create_time || "") + " "),
                ]),
              ])
            : _c("c-empty-page", {
                attrs: { tip: "请扫描或者输入自采品二维码~" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _vm.info.state == _vm.ONLINE_SELF_PROCUREMENT_STATUS_ENUM["10"].value
            ? _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.confirm } },
                [_vm._v("确认提交")]
              )
            : _vm._e(),
          _vm.info.state == _vm.ONLINE_SELF_PROCUREMENT_STATUS_ENUM["20"].value
            ? _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: { click: _vm.onPrintingSelf },
                },
                [_vm._v("打印自采单")]
              )
            : _vm._e(),
          _vm.info.state == _vm.ONLINE_SELF_PROCUREMENT_STATUS_ENUM["20"].value
            ? _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.onPrintLabel } },
                [_vm._v("打印标签")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("print-label", {
        ref: "printLabelRef",
        attrs: { windowList: _vm.windowList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }