<template>
  <!-- 零担复核 -->
  <div class="self-review bj">
    <div class="inputtop">
      <el-input
        style=""
        clearable
        v-model="code"
        placeholder="请在此输入或扫码复核码"
        autocomplete="off"
        autofocus="true"
        ref="inputfocusc"
        @keyup.enter.native="changeInput"
      />
      <div class="search">
        <el-button type="primary" icon="el-icon-search" @click="changeInput"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <div v-if="isShowInfo" class="inputbody">
        <div class="inputitems">
          <div class="inputwen">城市仓:</div>
          <div style="width: 300px; margin: 0 20px; font-size: 22px">
            {{ info?.store_logistics?.name || "" }}
          </div>
          <div class="inputwen">自提点:</div>
          <div style="width: 300px; font-size: 22px">
            {{ info?.store_delivery?.name || "" }}
          </div>
        </div>
        <div class="inputitems" style="align-items: flex-start">
          <div class="inputwen1 inputwen">自采品订单：</div>
          <div class="cntsbody">
            <div
              class="cntlist"
              v-for="(
                item, index
              ) in info?.store_logistics_tonnage_order_detail"
              :key="index"
            >
              {{ item?.product_name || "" }}
              <span>{{ item?.product_qty }}</span
              >件 共<span>{{ item?.product_weight }}</span
              >斤
              <div style="display: flex; align-items: center">
                复核：{{ item?.product_name }}

                <span>{{ item?.qc_qty }}</span>
                件,共
                <span>{{ item?.qc_weight }}</span>
                斤
              </div>
            </div>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">收货人:</div>
          <div class="widthinput">
            <el-input
              v-model="info.receiver_name"
              placeholder="收货人姓名"
              disabled
              clearable
            ></el-input>
          </div>
          <div class="widthinput">
            <el-input
              v-model="info.receiver_mobile"
              placeholder="收货人电话"
              disabled
              clearable
            ></el-input>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">发货人:</div>
          <div class="widthinput">
            <el-input
              v-model="info.shipper_name"
              placeholder="发货人姓名"
              disabled
              clearable
            ></el-input>
          </div>
          <div class="widthinput">
            <el-input
              v-model="info.shipper_mobile"
              placeholder="发货人电话"
              disabled
              clearable
            ></el-input>
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">用户姓名:</div>
          <div style="width: 300px; margin: 0 20px; font-size: 22px">
            {{ info?.member?.fullname || "" }}
          </div>
          <div class="inputwen">电话:</div>
          <div style="width: 300px; font-size: 22px">
            {{ info?.member?.mobile || "" }}
          </div>
        </div>
        <div class="inputitems">
          <div class="inputwen">下单时间:</div>
          {{ info?.create_time || "" }}
        </div>
      </div>
      <c-empty-page v-else tip="请扫描或者输入自采品二维码~"></c-empty-page>
    </div>
    <div class="btn">
      <el-button
        type="warning"
        @click="confirm"
        v-if="info.state == ONLINE_SELF_PROCUREMENT_STATUS_ENUM['10'].value"
        >确认提交</el-button
      >
      <el-button
        type="warning"
        @click="onPrintingSelf"
        v-if="info.state == ONLINE_SELF_PROCUREMENT_STATUS_ENUM['20'].value"
        >打印自采单</el-button
      >
      <el-button
        type="warning"
        @click="onPrintLabel"
        v-if="info.state == ONLINE_SELF_PROCUREMENT_STATUS_ENUM['20'].value"
        >打印标签</el-button
      >
    </div>

    <!-- 打印标签弹窗 start -->
    <print-label ref="printLabelRef" :windowList="windowList" />
    <!-- 打印标签弹窗 end  -->
  </div>
</template>
<script>
import CEmptyPage from "@/components/common/CEmptyPage";
import PrintLabel from "./modules/print-label/index";
import {
  postTonnageOrderDetail,
  postTonnageOrderFinish,
} from "@/api/logisticsControl/online-self-procurement";
import { ONLINE_SELF_PROCUREMENT_STATUS_ENUM } from "@/utils/enum/online-self-procure.js";
import { BASE } from "@/api";

export default {
  name: "zero-carry-review",
  components: { CEmptyPage, PrintLabel },
  data() {
    return {
      code: "",
      codecopy: "",
      isShowInfo: false,
      info: "",
      cityStoreList: [],
      zitList: [],
      windowList: [],
      ONLINE_SELF_PROCUREMENT_STATUS_ENUM,
    };
  },
  created() {},
  mounted() {
    this.$refs.inputfocusc.focus();
    this.onInitData();
  },
  methods: {
    /** 初始化 */
    onInitData() {
      this.roomWindows();
    },
    roomWindows() {
      this.$api.logistics.roomWindows().then((res) => {
        if (res.code == 20000) {
          this.windowList = res.data;
        }
      });
    },
    /** 打印标签 */
    onPrintLabel() {
      this.$refs.printLabelRef.onInitData(this.info);
    },
    /** 打印自采单 */
    onPrintingSelf() {
      window.open(
        `${BASE.PRO2 + "/tonnageOrder/pdf/print"}` +
          `?order_no=${this.code}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }`
      );
    },
    /** 确认提交 */
    async confirm() {
      try {
        const res = await postTonnageOrderFinish({
          order_no: this.codecopy,
        });
        this.$message({
          type: "success",
          message: "复核成功",
        });
        this.changeInput();
      } catch (error) {
        console.log(error, "postTonnageOrderFinish");
      }
    },
    // 文本框复核码
    async changeInput() {
      if (this.code) {
        try {
          const res = await postTonnageOrderDetail({
            order_no: this.code,
          });
          console.log(res, "获取数据");
          this.isShowInfo = true;
          this.info = res.data;
          this.codecopy = this.code;
          console.log(res, this.info, "获取数据");
        } catch (error) {
          console.log(error, "postTonnageOrderDetail");
          this.isShowInfo = false;
        }
      } else {
        this.isShowInfo = false;
      }
    },
  },
};
</script>
<style lang="scss">
.self-review {
  height: 100%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
  .inputbody {
    padding: 40px 100px;
    .inputitems {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 22px;
      .inputwen {
        font-size: 30px;
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      .inputwen1 {
        align-items: flex-start;
      }
      .widthinput {
        width: 300px;
        margin: 0 20px;
      }

      .cntsbody {
        .cntlist {
          font-size: 22px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          div {
            font-size: 22px;
            margin-left: 20px;
          }
          span {
            color: #13ae67;
            font-size: 22px;
            margin: 0 20px;
          }
        }
        display: flex;
        flex-direction: column;
      }
    }
  }

  .inputtop {
    display: flex;
    margin-bottom: 6px;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .search {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 8px;
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: auto;
  }
  .btn {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;

    box-sizing: border-box;
    justify-content: center;
  }
}
</style>
